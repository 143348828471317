import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import Emoji from '../components/emoji';
import { css } from '@emotion/core';

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`phone case`, `custom phone case`, `banana park`]}
    />
    <div
      css={css`
        margin: 50px auto;
        max-width: 440px;

        @media only screen and (min-width: 480px) {
          margin: 100px auto;
        }
      `}
    >
      <h1
        css={css`
          font-family: 'Montserrat', Helvetica, Arial, sans-serif;
          font-weight: bold;
        `}
      >
        Custom Phone Cases
      </h1>
      <p>
        Get the phone look you want. <Emoji label="Smartphone" emoji="📲" />
      </p>
      <p>
        Printed here in Australia. <Emoji label="Australian Flag" emoji="🇦🇺" />
      </p>
      <Link to="/custom-phone-case/">
        <Button>Get Customising!</Button>
      </Link>
    </div>
  </Layout>
);

export default IndexPage;
