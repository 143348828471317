import React from 'react';
import { css } from '@emotion/core';

export default props => (
  <span
    role="img"
    aria-label={props.label}
    css={css`
      font-size: 25px;
    `}
  >
    {props.emoji}
  </span>
);
